























import { defineComponent, ref } from '@vue/composition-api';
import { useUserGetters } from '@/store';

export default defineComponent({
  name: 'CheckoutOverlay',
  components: {
    MUseToken: () => import('@/components/molecules/m-use-token.vue')
  },
  props: {
    fetchProgram: {
      type: Function,
      default: () => {}
    }
  },
  setup(props, ctx) {
    const { getProgramDoc } = useUserGetters(['getProgramDoc']);
    const checkout = ref();
    const programDoc: any = {
      data: {
        ...getProgramDoc?.value
      }
    };
    function openCheckout() {
      checkout.value.dialog = true;
    }
    return {
      programDoc,
      checkout,
      openCheckout
    };
  }
});
